@if (profile$ | ngrxPush; as profile) {
  @if ((numberOfValidPrescribers === 0 || !profile.hasSignatureImage) && prescribingState) {
    <div class="banner -warning">
      <ul>
        @if ((isProvider$ | ngrxPush) && !profile.hasSignatureImage) {
          <li>
            You are missing a Signature Image. This will prevent you from signing medications on behalf of yourself.
          </li>
        }
        @if (numberOfValidPrescribers === 0 && profile.epcsEulaMissing) {
          <li>
            You do not have the proper prescribing credentials. Prescribers must sign the
            <a
              omgLegacyUiHref="/admin/controlled_substance_eula"
              title="EULA"
            >
              EULA
            </a>
            to prescribe controlled substances.
          </li>
        }
        @if ((numberOfValidPrescribers === 0 && !(patientIsVirtual$ | ngrxPush)) || !(isProvider$ | ngrxPush)) {
          <li>
            You do not have {{ hasControlledSubstances ? 'controlled substance' : '' }}
            prescribing credentials for
            {{ pharmacyAddress?.state }}. If you believe this is an error, please contact creds&#64;onemedical.com.
          </li>
        }
        @if ((numberOfValidPrescribers === 0 && (patientIsVirtual$ | ngrxPush)) || !(isProvider$ | ngrxPush)) {
          <li>
            You do not have the correct prescribing credentials for this OM Now patient. If you believe this is an
            error, please contact creds&#64;onemedical.com.
          </li>
        }
      </ul>
    </div>
  }
}
