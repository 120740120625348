// @ts-strict-ignore
import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { StateSelector } from '@app/core/store/shared/state';

import { VitalsData } from '../shared/vitals-data.type';
import { LoadVitalsDataPayload } from './vitals-data.actions';
import { VitalsDataState } from './vitals-data.reducer';

export class VitalsDataSelectors extends StateSelector<VitalsData> {
  protected selectState = createFeatureSelector<VitalsDataState>(
    this.stateConfig.statePath,
  );

  protected selectParams = createSelector(
    this.selectState,
    state => state.params,
  );

  protected selectAddingVitals = createSelector(
    this.selectState,
    state => state.addingVitals,
  );

  protected selectShowTime = createSelector(
    this.selectState,
    state => state.showTime,
  );

  protected selectVitalStagedForDeletion = createSelector(
    this.selectState,
    state => state.vitalStagedForDeletion,
  );

  get params(): Observable<LoadVitalsDataPayload> {
    return this.store.pipe(select(this.selectParams));
  }

  get addingVitals(): Observable<boolean> {
    return this.store.pipe(select(this.selectAddingVitals));
  }

  get showTime(): Observable<boolean> {
    return this.store.pipe(select(this.selectShowTime));
  }

  get vitalStagedForDeletion(): Observable<VitalsData | null> {
    return this.store.pipe(select(this.selectVitalStagedForDeletion));
  }
}
