import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Input,
  OnInit,
  Signal,
} from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';

import {
  PatientAddress,
  PatientSelectors,
  Profile,
  ProfileSelectors,
} from '@app/core';
import { PharmacyAddress } from '@app/modules/pharmacy-picker/shared/pharmacy.type';

@Component({
  selector: 'omg-rx-credential-warning',
  templateUrl: './rx-credential-warning.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RxCredentialWarningComponent implements OnInit {
  @Input() numberOfValidPrescribers: number;
  @Input() hasControlledSubstances: boolean;
  @Input() pharmacyAddress: PharmacyAddress;
  @Input() pharmacyIsMailOrder: boolean;

  profile$: Observable<Profile>;
  isProvider$: Observable<boolean>;
  patientIsVirtual$: Observable<boolean>;
  prescribingState: any;

  patientSelectors = inject(PatientSelectors);
  patientPrimaryAddress: Signal<PatientAddress | undefined> = toSignal(
    this.patientSelectors.primaryAddress,
  );

  constructor(private profileSelectors: ProfileSelectors) {}

  ngOnInit(): void {
    this.setupSelectors();

    this.prescribingState = computed(() =>
      this.pharmacyIsMailOrder
        ? this.patientPrimaryAddress()?.state.shortName
        : this.pharmacyAddress?.state,
    );
  }

  private setupSelectors(): void {
    this.profile$ = this.profileSelectors.profile;
    this.isProvider$ = this.profileSelectors
      .hasRole('provider')
      .pipe(take(1), shareReplay(1));
    this.patientIsVirtual$ = this.patientSelectors.isVirtual;
  }
}
