<ng-container *ngIf="renewal$ | ngrxPush as renewal">
  <form
    [omgForm]="form.model"
    [formGroup]="form.controls"
    class="-immediate-errors"
  >
    <omg-renewal-item-subheader [renewal]="renewal"></omg-renewal-item-subheader>
    <omg-task-assignment [todo]="todo$"></omg-task-assignment>
    <omg-renewal-item-requested-by [renewal]="renewal"></omg-renewal-item-requested-by>
    <div *ngIf="pediatricVitalsEnabled$ | ngrxPush">
      <omg-pediatric-vitals (vitalsStatus)="updatePediatricVitalsStatus($event)"></omg-pediatric-vitals>

      <hr class="-spaced" />
    </div>
    <div
      class="padding-normal"
      om-layout="vertical"
      om-layout-gutter
    >
      <omg-cart-item-name
        [name]="renewal.medForDisplay.name"
        [medication]="renewal"
        [description]="renewal.medForDisplay.dispensableTextDesc"
        [instructions]="renewal.medForDisplay.instructions"
        [deaSchedule]="renewal.dispensableDeaCode"
        [forceEdit]="longSig"
        [editable]="true"
        [heading]="true"
        (regimenUpdate)="regimenUpdate($event)"
        (expanded)="setRegimenEditingInProgress($event)"
      ></omg-cart-item-name>
      <div
        om-layout
        omgCollapse
        #moreOptionsCollapseRef="omgCollapse"
        [expanded]="true"
        om-layout-gutter-large
        om-layout-align="start center"
      >
        <omg-renewal-item-qty
          [quantity]="form.controls.get('approvedQuantity')"
          [medicationPackageSizeId]="form.controls.get('approvedMedicationPackageSizeId')"
          [packageOptions]="renewal.packageOptions"
          [focusOnMedicationKey]="renewal.id"
        ></omg-renewal-item-qty>
        <omg-renewal-item-fills [fills]="form.controls.get('approvedFills')"></omg-renewal-item-fills>

        <omg-cart-item-more-options-toggle
          [collapseProvider]="moreOptionsCollapseRef"
          [visible]="true"
        ></omg-cart-item-more-options-toggle>
      </div>

      <div
        class="om-messages"
        *ngFor="let error of form.updateErrors"
      >
        {{ error }}
      </div>

      <omg-cart-item-more-options
        [collapseProvider]="moreOptionsCollapseRef"
        [dispenseAsWritten]="form.controls.get('dispenseAsWritten')"
        [notesToPharmacistRequired]="renewal.requiresNoteOfMedicalNeed"
        [notesToPharmacist]="form.controls.get('notesToPharmacist')"
        [maxNotesLength]="renewal.noteToPharmacistMaxCharacters"
        [notesAddenda]="renewal.notesLegalAddendum"
        [fillAfterDate]="form.controls.get('earliestFillDate')"
        [fillAfterDateRequired]="renewal.dispensableRestrictedControlledSubstance"
        [fillAfterDateVisible]="renewal.dispensableControlledSubstance"
        [maxEarliestFillDate]="renewal.maxEarliestFillDate"
      ></omg-cart-item-more-options>

      <!-- Problems Attachments Table-->
      <ng-container *ngIf="problemToMedsLinkingEnabled">
        <ng-container *ngIf="attachedProblemIds$ | ngrxPush as problems">
          @if (shouldDisplayProblemAttachmentsComponent(problems, renewal)) {
            <omg-problem-attacher-table
              [currentSelectionIds]="problems"
              [editable]="linkedProblemsEditingEndabled"
              (selectionRemoved)="problemAttachmentRemoved($event, renewal.patientMedicationId)"
              (selectionAdded)="problemAttachmentAdded($event, renewal.patientMedicationId)"
              data-cy="renewal-problem-attachments"
            />
          }
        </ng-container>
      </ng-container>

      <omg-renewal-note-banners
        [fromPharmacist]="renewal.notesFromPharmacist"
        [fromPatient]="renewal.commentFromPatient"
        [cancellationReason]="renewal.cancellationReason"
      ></omg-renewal-note-banners>

      <omg-mismatched-patient-details
        *ngIf="renewal.mismatchedPatient"
        [details]="renewal.mismatchedPatient"
        [pharmacyName]="renewal.pharmacy?.name"
      ></omg-mismatched-patient-details>
      <omg-rx-item-pharmacy [pharmacy]="renewal.pharmacy"></omg-rx-item-pharmacy>
      <hr class="-spaced" />

      <omg-rx-item-sign-and-dea
        *ngIf="userCanPrescribe || isProvider"
        [prescriber]="renewal.prescriber"
        [validPrescribers]="sortedValidPrescribers$ | ngrxPush"
        [loading$]="credentialsLoading$"
        [credentialItems$]="credentialItems$"
        [prescriberId]="form.controls.get('prescriberId')"
        [prescribingCredentialId]="form.controls.get('prescribingCredentialId')"
      ></omg-rx-item-sign-and-dea>
      <omg-rx-credential-warning
        [hasControlledSubstances]="renewal.dispensableControlledSubstance"
        [pharmacyAddress]="renewal.pharmacy.address"
        [numberOfValidPrescribers]="numberOfValidPrescribers"
        [pharmacyIsMailOrder]="renewal.pharmacy.isMailOrder"
      ></omg-rx-credential-warning>
    </div>
    <omg-renewal-item-action-bar
      [cartState]="form.controls.get('cartState')"
      [denialReason]="form.controls.get('denialReason')"
      [renewal]="renewal"
      [disabledApproval]="disabledApproval"
      [disabledDenial]="disabled"
    ></omg-renewal-item-action-bar>
    <ng-container *ngIf="!(cartComplete$ | ngrxPush)">
      <omg-ready-for-checkout [isListView]="false"></omg-ready-for-checkout>
    </ng-container>
  </form>
</ng-container>
