import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { ApiService } from '@app/core/api/api.service';
import { PatientEntityApiService } from '@app/core/store/shared/entity-api-service';
import { snakeCase } from '@app/utils';

import { loincCodes, VitalsData } from './vitals-data.type';

// includes getById(), getAll(), update(), delete(), and save() by default
@Injectable({
  providedIn: 'root',
})
export class VitalsDataApiService extends PatientEntityApiService<VitalsData> {
  constructor(api: ApiService, patientSelectors: PatientSelectors) {
    super(
      api,
      {
        basePath: '/v2/admin/patients/:patientId/vitals',
        params: null,
      },
      patientSelectors,
    );
  }

  getVitalsBy(params): Observable<VitalsData[]> {
    return this.api.get<any>(this.basePath, snakeCase(params), {}, true).pipe(
      map(vitalsResponse => vitalsResponse.vitals),
      catchError((error: HttpErrorResponse) => throwError(error)),
    );
  }

  saveVitals(patientId, vitalsParams): Observable<VitalsData> {
    return this.api
      .save<any>(
        this.basePath,
        translateSaveVitalsParams(vitalsParams),
        {},
        true,
      )
      .pipe(
        map(response => response),
        catchError((error: HttpErrorResponse) => throwError(error)),
      );
  }

  deleteVital(measurementIds: number[]): Observable<boolean> {
    return this.api
      .save(
        `${this.basePath}/destroy_by_measurement_ids`,
        { measurement_ids: measurementIds },
        {},
        true,
      )
      .pipe(
        map(response => true),
        catchError((error: HttpErrorResponse) => throwError(error)),
      );
  }
}

export interface VitalApiData {
  value: number;
  loinc_code: string;
}

export const mapVitalsFormToApiParams = (vitalsForm): VitalApiData[] => {
  return Object.entries(vitalsForm).map(([key, value]) => {
    return { loinc_code: loincCodes[key] as string, value: value as number };
  });
};

function translateSaveVitalsParams(params: {
  vitalsForm: VitalApiData[];
  comment: string;
  collectedAt?: string;
}): { measurements: VitalApiData[]; comments: string; collectedAt?: string } {
  return {
    measurements: mapVitalsFormToApiParams(params.vitalsForm),
    comments: params.comment,
    collectedAt: params.collectedAt,
  };
}
